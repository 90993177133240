import React, { Suspense } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import { BoxLoading } from 'react-loadingg';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import lazyImport from './lazyImport';
import './App.scss';

const Home = lazyImport('./screens/Home');
const About = lazyImport('./screens/About');
const Contact = lazyImport('./screens/Contact');
const Cars = lazyImport('./screens/SelfDrivenCars');
const ReserveVehicle = lazyImport('./screens/ReserveVehicle');

const LoadingMessage = () => (
  <div
    style={{
      position: 'absolute',
      top: '50%',
      left: '50%',
    }}
  >
    <BoxLoading />
  </div>
);

const store = createStore(rootReducer, compose(applyMiddleware(thunk)));

function App() {
  return (
    <Provider store={store}>
      <Router>
        <div>
          <Suspense fallback={<LoadingMessage />}>
            <Switch>
              <Route path="/cars">
                <Cars />
              </Route>

              <Route path="/about">
                <About />
              </Route>

              <Route path="/contact">
                <Contact />
              </Route>

              <Route path="/reserve">
                <ReserveVehicle />
              </Route>

              <Route>
                <Home />
              </Route>
            </Switch>
          </Suspense>
        </div>
        <a
          className="whatsapp-widget"
          href="https://api.whatsapp.com/send?phone=919822483068"
          target="_blank"
        >
          <WhatsAppIcon id="whatsapp-icon" />
          <span>Need help?</span>
        </a>
      </Router>
    </Provider>
  );
}

export default App;
