import { EXECUTION_STATUS, GET_CARS } from '../actions/actionTypes';

const INITIAL_STATE = {
  cars: [],
  isLoading: false,
};

export default function (state = INITIAL_STATE, action) {
  let { payload } = action;
  const { STARTED, FINISHED, ERROR } = EXECUTION_STATUS;
  switch (action.type) {
    ////////////////////////////////////////////////////////////////////////////////////////

    case GET_CARS + STARTED:
      return {
        ...state,
        isLoading: true,
      };
    case GET_CARS + FINISHED:
      return {
        ...state,
        cars: payload,
        isLoading: false,
      };
    case GET_CARS + ERROR:
      return {
        ...state,
        isLoading: false,
        error: 'Error while loading cars',
      };

    default:
      return state;
  }
}
