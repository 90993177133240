import app from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

class Firebase {
  constructor() {
    app.initializeApp(config);
    this.analytics = app.analytics();
    this.googleProvider = app.auth.GoogleAuthProvider;
    this.firebaseAuth = app.auth();
    this.ref = app.database().ref();
    this.fireDB = app.firestore();
  }

  loginWithGoogle = () => {
    return this.firebaseAuth.signInWithPopup(this.googleProvider);
  };

  logout = () => {
    return this.firebaseAuth.signOut();
  };

  firebaseAnalytics = () => {
    return this.analytics;
  };
}

export default Firebase;
